import axios from "axios";

export function genRandString(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export async function getSessionId() {
  let out = {};

  const storedSid = JSON.parse(localStorage.getItem('sessionid'));
  const storedCsrf = JSON.parse(localStorage.getItem('csrf'));

  if ((storedSid && storedSid.expiration > new Date().getTime()) && (storedCsrf && storedCsrf.expiration > new Date().getTime())) {
    // The item has not expired, use sessionid from localStorage 
    out = {
      sessionid: storedSid.data.sessionid,
    }
  } else {
    // The item has expired or doesn't exist, clear it from localStorage
    localStorage.removeItem('sessionid');
    localStorage.removeItem('csrf');

    // and request the new one here.
    let result = await axios.get("/gkey").then((response) => response);
    // result = genRandString(8);
  
    // Store an sessionid with timestamp in localStorage
    let data = { sessionid: result.data.sessionid };
    let expirationTime = new Date().getTime() + 3600 * 1000; // Set expiration time to 3600  second from now
    let item = { data: data, expiration: expirationTime };
    localStorage.setItem('sessionid', JSON.stringify(item));

    // Store an csrf with timestamp in localStorage
    data = { csrf: result.data.csrf };
    expirationTime = new Date().getTime() + 120 * 1000; // Set expiration time to 30 second from now
    item = { data: data, expiration: expirationTime };
    localStorage.setItem('csrf', JSON.stringify(item));
    out = {
      sessionid: result.data.sessionid,
      csrf: result.data.csrf,
    };
  }
  
  return out;
}