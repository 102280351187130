<script>
  // import bootstrap from 'bootstrap';
  import { genRandString, getSessionId } from "../common";
  import axios from "axios";

  export default {
    name: "LoginPage",
    data() {
      return {
        unamefieldname: genRandString(8),
        upassfieldname: genRandString(8),
        csrffield: genRandString(8),
        csrf: "",
        sid: "",
      }
    },
    async created(){
      let sessions = await getSessionId(); // NOTE: get sessionid from browser localstorage, if present use it, else generate a new one and store to redis.
      let csrf = sessions.csrf;
      let sessionid = sessions.sessionid;
      this.csrf = csrf;
      this.sessionid = sessionid;
    },
    methods: {
      handleSubmit() {
        let sru = new URL(window.location.href);
        let formData = new FormData();
        formData.append('username', this.username);
        formData.append('password', this.password);
        formData.append('csrf', this.csrf);
        formData.append('sid', this.sessionid);
        formData.append('ref', window.location.href);

        axios.post(`/login`, formData)
        .then(response => {
          if (response.status == 410) {
            window.location.reload();
          }
          if (response.status == 200) {
            let u = new URL(response.headers.location);
            u.searchParams.set("sid", this.sessionid);
            if (sru.searchParams.has("origin")) {
              u.searchParams.set("origin", sru.searchParams.get("origin"));
            }
            window.location.href = u.href;
          }
        })
        .catch(error => {
          console.error(error);
        });
      }
    }
  }
</script>
<template>
  <div id="LoginWrap" class="col-10 col-sm-8 col-md-6 col-lg-5 col-xl-4 center">
    <div class="row">
      <form method="post" enctype="application/x-www-form-urlencoded" action="https://www.example.com/">
        <label class="h" for="username">Username : </label>
        <input class="h" type="text" name="username" id="username" />
        <label class="h" for="password">Password : </label>
        <input class="h" type="password" name="password" id="password" />
        <button class="h">Login</button>
      </form>
      <form @submit.prevent="handleSubmit">
        <input type="hidden" :name="csrffield" :value="csrf" />
        <!-- Username input -->
        <div class="form-floating mb-4">
          <input type="text" :name="unamefieldname" v-model="username" placeholder="username" id="form2Example1" class="form-control" />
          <label class="form-label" for="form2Example1">Username</label>
        </div>

        <!-- Password input -->
        <div class="form-floating mb-4">
          <input type="password" :name="upassfieldname" v-model="password" placeholder="password" id="form2Example2" class="form-control" />
          <label class="form-label" for="form2Example2">Password</label>
        </div>

        <!-- 2 column grid layout for inline styling -->
        <div class="row mb-4">
          <div class="col d-flex justify-content-center">
            <!-- Checkbox -->
            <!-- <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="form2Example31" checked />
              <label class="form-check-label" for="form2Example31"> Remember me </label>
            </div> -->
          </div>

          <div class="col">
            <!-- Simple link -->
            <!-- <a href="#!">Forgot password?</a> -->
          </div>
        </div>

        <!-- Submit button -->
        <button type="submit" class="btn btn-primary btn-block mb-4">Sign in</button>

        <!-- Register buttons -->
        <div class="text-center">
          <!-- <p>Not a member? <a href="#!">Register</a></p>
          <p>or sign up with:</p>
          <button type="button" class="btn btn-link btn-floating mx-1">
            <font-awesome-icon :icon="['fab', 'fa-facebook-f']" />
          </button>

          <button type="button" class="btn btn-link btn-floating mx-1">
            <font-awesome-icon :icon="['fab', 'fa-google']" />
          </button>

          <button type="button" class="btn btn-link btn-floating mx-1">
            <font-awesome-icon :icon="['fab', 'fa-twitter']" />
          </button>

          <button type="button" class="btn btn-link btn-floating mx-1">
            <font-awesome-icon :icon="['fab', 'fa-github']" />
          </button> -->
        </div>
      </form>
    </div>
  </div>
</template>

<style lang="scss">
  @import '../assets/styles.scss';

  .h {
    display: none;
  }
</style>