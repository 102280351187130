import { createApp } from 'vue'
import App from './App.vue'

import { library } from '@fortawesome/fontawesome-svg-core'
// import { fas } from '@fortawesome/free-solid-svg-icons'
// import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// library.add(fas, far, fab)
library.add(fab)

// import * as mdb from 'mdb-ui-kit';

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'
import 'mdb-ui-kit/css/mdb.min.css';


let app = createApp(App);
app.component('font-awesome-icon', FontAwesomeIcon)
app.mount('#app');
